

import React from "react"
import { Container } from "reactstrap"

import BackgroundSlider from './BackgroundSlider'
import { useStaticQuery } from "gatsby"
import { graphql } from 'gatsby' 
import "../styles/components/hero.scss"

import companyThumbnail from "../images/fahr_erlebnis_weiss_transparent.png"
import { divIcon } from "leaflet"


const HeroSlider = ({
    title="FAHR ERLEBNIS",
    subtitle="Die Erlebnis Oase im Limmattal",
    image,
    fullScreen=false
}) => {
    // console.log(image)

  return (
    <div style={{height:'100vh',}} >

    <div
      className="hero"
      id="hero"
    >
      <section className=" h-100 d-flex align-items-center text-center bg-dark-100">
        <Container>
        {/* <img style={{maxWidth:'210px'}}
            src={companyThumbnail}
            alt="fahr erlebnis logo"
            className="img-head-home"
        />   */}
          <main className="hero-content">
          <h1 className="title">{title}</h1>
            

          <p className="hero-subber">{subtitle}</p>
          </main>
        </Container>
      </section>
    </div>

    <BackgroundSlider
    // query={useStaticQuery(graphql`
    //   query {
    //     backgrounds: 
    //     allContentfulHomepageSliderBilder {
    //       nodes {
    //         slidesHomepage {
    //           gatsbyImage(width: 1920)
    //         }
    //       }
    //     }
    //   }
    // `)}

    query={useStaticQuery(graphql`
    query {
      backgrounds: 
      allContentfulHomepageSliderBilder {
        nodes {
          slidesHomepage {
            gatsbyImageData(width: 1920)
          }
        }
      }
    }
  `)}
    initDelay={2}
    transition={4}
    duration={7}
    style={{
      transform: "rotate(0deg) scale(1)",
    }}

  />
  
  </div>
  )
}

export default HeroSlider
