import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

const ErlebnisseK = () => {
  const data = useStaticQuery(graphql`
  query {
  allContentfulErlebnisseTextHomepage(filter: {id: {eq: "f18886db-ef75-57be-bb0e-c805d2827d02"}}) {
    edges {
      node {
        beschreibung {
          beschreibung
        }
      }
    }
  }
 }   `)


  const items = data.allContentfulErlebnisseTextHomepage.edges;
  return (
    <div>
      <ul className="menu-items-grid">
        {
          items.map(({ node, i }) => {
            return (
              <li key={i} className="menu-item">
                <span>{node.beschreibung.beschreibung}</span>
              </li>
            )
          })
        }
      </ul>
      <br></br>

      <a className="menu-link" href="https://fahr-erleben.ch"  rel="noopener noreferrer">Mehr dazu</a>

    </div>
  )
}

export default ErlebnisseK;